import React, {Fragment} from 'react';
import Logo from "../../components/elements/Logo";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import SocialMenu from "../../components/elements/SocialMenu";
import RightImage from "../../components/elements/RightImage";
import MicRightImage from "../../assets/images/right/right-mic.jpg";
import ImageWithLine from "../../components/elements/content/ImageWithLine";
import InfoActsImage1 from "../../assets/images/info/info-acts-1.jpg";
import InfoActsImage2 from "../../assets/images/info/info-acts-2.jpg";
import InfoActsMobileMic from "../../assets/images/info/info-acts-mobile-mic.jpg";
import InfoLocationImage1 from "../../assets/images/info/info-location-1.jpg";
import InfoLocationImage2 from "../../assets/images/info/info-location-2.jpg";
import InfoLocationImage3 from "../../assets/images/info/info-location-3.jpg";
import InfoArrivalImage1 from "../../assets/images/info/info-arrival-1.jpg";
import InfoContactImage1 from "../../assets/images/info/info-contact-1.jpg";
import InfoAwarenessImage from "../../assets/images/info/awareness.jpg";
import InfoContactImage2 from "../../assets/images/info/info-contact-2.jpg";
import TextBlockWithLine from "../../components/elements/content/TextBlockWithLine";
import {Route, Switch} from "react-router";
import Button from "../../components/elements/Button";
import InfoTopRightMenu from "./InfoTopRightMenu";
import NewsletterEntry from "./NewsletterEntry";
import piInfoArrival1 from '../../assets/images/info/info-arrival-1.jpg';
import piInfoContact1 from '../../assets/images/info/info-contact-1.jpg';
import piInfoContact2 from '../../assets/images/info/info-contact-2.jpg';
import piInfoLocation1 from '../../assets/images/info/info-location-1.jpg';
import piInfoLocation2 from '../../assets/images/info/info-location-2.jpg';
import piInfoLocation3 from '../../assets/images/info/info-location-3.jpg';
import Preload from "react-preload";
import ShowLoader from "../../components/elements/ShowLoader";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import PhilosophyImage1 from "../../assets/images/philosophy/philosophy-1.jpg";
import PhilosophyImage2 from "../../assets/images/philosophy/philosophy-2.jpg";
import kidsspacePdf from '../../assets/kidsspace.pdf'
import animateScrollTo from "animated-scroll-to";
import PodcastLink from "../../components/elements/PodcastLink";
import gfx_sponsor_im from "../../assets/images/info/sponsor_initiative-musik.svg";
import gfx_sponsor_nsk from "../../assets/images/info/sponsor_nsk.png";
import gfx_sponsor_brdkm from "../../assets/images/info/sponsor_brd-kultur-medien.svg";

class Info extends React.Component {

    state = {
        activeItem: 'philosophy'
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if(this.props.history && this.props.history.location && this.props.history.location.pathname === '/newsletter') {
            window.setTimeout(() => animateScrollTo(document.querySelector('.info-contact')), 1000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let elements = [
            {
                selector: '.info-philosophy',
                name: 'philosophy',
                offset: 0
            },
            {
                selector: '.info-location',
                name: 'location',
                offset: 100
            },
            {
                selector: '.info-awareness',
                name: 'awareness',
                offset: 100
            },
            {
                selector: '.info-contact',
                name: 'contact',
                offset: 100
            },
        ];
        let closestIndex = 0;
        let closestLength = Infinity;
        for(let i = 0; i < elements.length; i++) {
            let length = document.querySelector(elements[i].selector).getBoundingClientRect().top;
            if(i === 0) {
                document.querySelector('.background-small-box').setAttribute('style', 'bottom: ' + (length + window.outerHeight) + 'px');
            }
            let absLength = Math.abs(length + elements[i].offset);
            if(absLength < closestLength) {
                closestLength = absLength;
                closestIndex = i;
            }
        }
        this.setState({
            activeItem: elements[closestIndex].name
        });
    };

    handleTopicScroll = () => {
        if ((this.props.match && this.props.match.params.topic && this.props.match.params.topic === 'awareness') || this.props.topicAwareness) {
            setTimeout(() => {
                animateScrollTo(document.querySelector('.info-awareness'), {
                    offset: 15
                });
            }, 100);
        }
    }

    render() {
        let images = [piInfoArrival1, piInfoContact1, piInfoContact2, piInfoLocation1, piInfoLocation2, piInfoLocation3, InfoAwarenessImage];
        return (
            <div className="Info">
                <Preload loadingIndicator={<ShowLoader/>} images={images} children={<div/>} onSuccess={this.handleTopicScroll}/>
                <RightImage source={MicRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        <PodcastLink/>
                    </div>
                </div>
                <div className="left-text-column">
                    <InfoTopRightMenu active={this.state.activeItem} />
                    <div className="line-left-100"/>
                    <div className="ImageWithLine-header imlh-minh-225">
                        <ImageWithLine lineColor="white" imageSource={InfoActsImage1}/>
                    </div>
                    <Fragment>
                        <div className="Philosophy info-philosophy">
                            <div className="content-container">
                                <div className="content">
                                    <HeadingWithLine lineColor="pink" textKey="philosophy.main.title" />
                                    <TextBlockWithLine lineColor="pink" textKey="philosophy.title" />
                                    <div className="background-small-box"><div className="background-small"><div className="line-left"/></div></div>
                                    <div className="philosophy-text-1 philosophy-text-1-fill">
                                        <TextBlockWithLine lineColor="pink" textKey="info.philosophy.text1"/>
                                    </div>
                                    <div className="philosophy-text-1">
                                        <TextBlockWithLine lineColor="pink" textKey="info.philosophy.text1"/>
                                    </div>
                                    <div className="philosophy-image-1">
                                        <div className="line-left"/>
                                        <ImageWithLine lineColor="white" imageSource={InfoActsImage2}/>
                                    </div>
                                    <div className="philosophy-text-2">
                                        <TextBlockWithLine lineColor="pink" textKey="info.philosophy.text2"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ImageWithLine-high info-location">
                            <ImageWithLine lineColor="white" imageSource={InfoLocationImage1}/>
                        </div>
                        <HeadingWithLine lineColor="pink" textKey="info.menu.location"/>
                        <div className="TextBlockWithLine-first TextBlockWithLine-first-location">
                            <TextBlockWithLine lineColor="pink" textKey="info.location.text1"/>
                        </div>
                        <div className="ImageWithLine-high">
                            <ImageWithLine lineColor="white" imageSource={InfoLocationImage3}/>
                        </div>
                        <div className="arrival-address-text info-arrival">
                            <HeadingWithLine lineColor="pink" textKey="info.menu.arrival"/>
                        </div>
                        <div className="TextBlockWithLine-first">
                            <TextBlockWithLine lineColor="pink" textKey="info.arrival.text1"/>
                        </div>
                        <ImageWithLine lineColor="white" imageSource={InfoLocationImage2}/>
                        <HeadingWithLine lineColor="pink" textKey="info.menu.kidsspace"/>
                        <div className="TextBlockWithLine-kidsspace">
                            <TextBlockWithLine lineColor="pink" textKey="info.kidsspace.text1"/>
                            {/*<a href={kidsspacePdf} download className="kidsspaceMoreInfo"><TextBlockWithLine textKey="info.kidsspace.pdfBtn"></TextBlockWithLine></a>
                            <TextBlockWithLine lineColor="pink" textKey="info.kidsspace.text2"/>*/}
                        </div>
                        <div className="ImageWithLine-high info-awareness">
                            <ImageWithLine lineColor="white" imageSource={InfoAwarenessImage}/>
                        </div>
                        <div className="awareness-text">
                            <HeadingWithLine lineColor="pink" textKey="info.awareness.title"/>
                        </div>
                        <div className="TextBlockWithLine-first">
                            <TextBlockWithLine lineColor="pink" textKey="info.awareness.text1"/>
                        </div>
                        <div className="contact-buttons awareness-buttons">
                            <div className={`line-left line-color-pink`} />
                            <div className="row-1">
                                <Button isRealLink={true} to="mailto:awareness@entropiefestival.de" titleKey="info.awareness.email"/>
                            </div>
                            <div className="row-1">
                                <Button isRealLink={true} to="mailto:flintawareness@entropiefestival.de" titleKey="info.awareness.emailFlint"/>
                            </div>
                        </div>
                        <div className="TextBlockWithLine">
                            <TextBlockWithLine lineColor="pink" textKey="info.awareness.text2"/>
                        </div>
                        <div className="ImageWithLine-high info-contact">
                            <ImageWithLine lineColor="white" imageSource={InfoArrivalImage1}/>
                        </div>
                        <HeadingWithLine lineColor="pink" textKey="info.menu.contact"/>
                        <div className="contact-buttons">
                            <div className={`line-left line-color-pink`} />
                            <div className="row-1">
                                <Button isRealLink={true} to="mailto:stress@entropiefestival.de" titleKey="info.contact.email"/>
                            </div>
                            <div className="row-2">
                                <div className="button-first">
                                    <Button isRealLink={true} target="_blank" to="https://instagram.com" titleKey="info.contact.instagram"/>
                                </div>
                                <Button isRealLink={true} target="_blank" to="https://facebook.com" titleKey="info.contact.facebook"/>
                            </div>
                            <div className="row-3">
                                <NewsletterEntry/>
                            </div>
                        </div>
                        <div className="sponsor-logos">
                            <div className="title-text">
                                Gefördert von:
                            </div>
                            <div className="images">
                                <img src={gfx_sponsor_nsk} alt=""/>
                                <img src={gfx_sponsor_im} alt=""/>
                                <img src={gfx_sponsor_brdkm} alt=""/>
                            </div>
                        </div>
                        <div className="ImageWithLine-high ImageWithLine-last">
                            <ImageWithLine lineColor="white" imageSource={InfoContactImage1}/>
                        </div>
                    </Fragment>
                </div>
            </div>
        )
    }
}

export default Info;
