import React from 'react';
import BridgeRightImage from "../../assets/images/right/right-bridge.jpg";
import RightImage from "../../components/elements/RightImage";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import {Route, Switch} from "react-router";
import SupporterIntro from "./SupporterIntro";
import SupporterRegister from "./SupporterRegister";
import SupporterAccount from "./SupporterAccount";
import PodcastLink from "../../components/elements/PodcastLink";

class Supporter extends React.Component {
    render() {
        return (
            <div className="Supporter">
                <RightImage source={BridgeRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        <PodcastLink/>
                    </div>
                </div>
                <Switch>
                    {/*<Route path="/supporter/register" render={() => (
                        <SupporterRegister/>
                    )}/>*/}
                    <Route path="/supporter/account" render={() => (
                        <SupporterAccount/>
                    )}/>
                    <Route path="/supporter" render={() => (
                        <SupporterIntro/>
                    )}/>
                </Switch>
            </div>
        )
    }
}

export default Supporter;