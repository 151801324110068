import React from 'react';
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import IntlMessages from "../../util/IntlMessages";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import PodcastContent from "./PodcastContent";
import closeGfx from "../../assets/images/close.svg";
import closeActiveGfx from "../../assets/images/close_active.svg";
import arrowGfx from "../../assets/images/podcast/arrow.svg";
import Button from "../../components/elements/Button";
import animateScrollTo from 'animated-scroll-to';
import PodcastLogo from "../../assets/images/podcast/entropiecast.svg";
import RightImage from "../../components/elements/RightImage";

class Podcast extends React.Component {

    state = {
        season: 3,
        activeEntry: 'https://soundcloud.com/entropiefestival/entropiecast020'
    };

    render() {
        let entries = PodcastContent.entries();
        return (
            <div className="Podcast">
                <RightImage source={PodcastLogo}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                    </div>
                </div>
                <div className="left-text-column">
                    <div className="header">
                        <h2>
                            <IntlMessages id="podcast.main.title"/>
                        </h2>
                        <div className="info">
                            <p>
                                <IntlMessages id="podcast.main.info"/>
                            </p>
                        </div>
                        <div className="notLineUpHint">
                            <p>
                                <IntlHtmlMessages id="podcast.main.notLineUpHint"/>
                            </p>
                        </div>
                    </div>
                    <div className="tab-main-container">
                        <div className="tabs">
                            <button onClick={() => this.setState({season: 3})} className={this.state.season === 3 ? 'active' : ''}>
                                Staffel 3
                            </button>
                            <button onClick={() => this.setState({season: 2})} className={this.state.season === 2 ? 'active' : ''}>
                                Staffel 2
                            </button>
                            <button onClick={() => this.setState({season: 1})} className={this.state.season === 1 ? 'active' : ''}>
                                Staffel 1
                            </button>
                        </div>
                        <div className="content">
                            {entries.filter(el => el.season === this.state.season).sort((a, b) => a.orderNumber - b.order-Number).map((entry, eI) => (
                                <div className={`entry entry-${entry.season}-${entry.orderNumber}`} key={eI}>
                                    <div className="soundcloud">
                                        <button className={`textBtn${this.state.activeEntry === entry.soundcloud ? ' active' : ''}`} onClick={() => {this.setState({activeEntry: entry.soundcloud}); animateScrollTo(document.querySelector(`.entry-${entry.season}-${entry.orderNumber}`))}}>
                                            TEXT
                                            <img src={arrowGfx} alt=""/>
                                        </button>
                                        <iframe width="100%" height="166" scrolling="no"
                                                frameBorder="no" allow="autoplay"
                                                src={`https://w.soundcloud.com/player/?url=${entry.soundcloud}&color=%230019ff&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true`}/>
                                    </div>
                                    <div className={`info${this.state.activeEntry === entry.soundcloud ? ' active' : ''}`}>
                                        <button className="closeButton" onClick={() => this.setState({activeEntry: null})}>
                                            <img src={closeGfx} alt="" className="default"/>
                                            <img src={closeActiveGfx} alt="" className="active"/>
                                        </button>
                                        {entry.info}
                                        <button className="closeButton closeButton-2" onClick={() => this.setState({activeEntry: null})}>
                                            <img src={closeGfx} alt="" className="default"/>
                                            <img src={closeActiveGfx} alt="" className="active"/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Button onClick={() => animateScrollTo(document.querySelector('.Podcast'))}>
                            <img src={arrowGfx} alt=""/>
                            <IntlMessages id="podcast.main.toTop"/>
                            <img src={arrowGfx} alt=""/>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Podcast;