import React from 'react';
import Logo from "../../components/elements/Logo";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import SocialMenu from "../../components/elements/SocialMenu";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import HomeShortLinks from "./HomeShortLinks";
import backgroundImage from "../../assets/images/home/background.gif";
import PodcastLink from "../../components/elements/PodcastLink";

class Home extends React.Component {
    render() {
        return (
            <div className="Home">
                <div className="backgrounds" style={{ backgroundImage: `url(${backgroundImage})`}}/>
                <div className="content-container">
                    <div className="content">
                        <Logo showFullLogo={true}/>
                        <Footer/>
                        <Menu/>
                        <SocialMenu whiteMode/>
                        <div className="title">
                            <IntlHtmlMessages id="home.title" />
                        </div>
                        <HomeShortLinks />
                        <PodcastLink/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
