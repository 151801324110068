import React, {Fragment} from 'react';
import connect from "react-redux/src/connect/connect";
import {saveStep1} from "../../actions";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import TicketingStepProgress from "./TicketingStepProgress";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import IntlMessages from "../../util/IntlMessages";
import ImgArrowDown from '../../assets/images/ticketing/arrow-down.svg';
import ImgArrowDownDisabled from '../../assets/images/ticketing/arrow-down-disabled.svg';
import ImgArrowUp from '../../assets/images/ticketing/arrow-up.svg';
import ImgArrowUpDisabled from '../../assets/images/ticketing/arrow-up-disabled.svg';
import Button from "../../components/elements/Button";
import ImgSoldOutDesktopDe from '../../assets/images/ticketing/sold_out-desktop-de.svg';
import ImgSoldOutDesktopEn from '../../assets/images/ticketing/sold_out-desktop-en.svg';
import ImgSoldOutTabletDe from '../../assets/images/ticketing/sold_out-tablet-de.svg';
import ImgSoldOutTabletEn from '../../assets/images/ticketing/sold_out-tablet-en.svg';
import ImgSoldOutMobileDe from '../../assets/images/ticketing/sold_out-mobile-de.svg';
import ImgSoldOutMobileEn from '../../assets/images/ticketing/sold_out-mobile-en.svg';

class TicketingStep1 extends React.Component {
    state = {
        selectedProducts: [],
        errorNothingSelected: false
    };

    componentWillMount() {
        this.initSelectedProducts();
    }

    initSelectedProducts = () => {
        let selectedProducts = [];
        if(this.props.ticketing.selectedProducts) {
            selectedProducts = this.props.ticketing.selectedProducts;
        } else {
            this.props.ticketing.availableProducts.filter((el) => el.availableForTicketShop).map((product) => {
                selectedProducts.push({...product, amount: product.id === 3 ? /*1*/0 : 0});
            });
        }
        this.setState({
            selectedProducts: selectedProducts
        });
    };

    handleIncrementProduct = (productId) => {
        let selectedProducts = this.state.selectedProducts;
        selectedProducts = selectedProducts.map((product) => {
            if(product.id === productId && product.amount < 10) {
                product.amount += 1;
            }
            return product;
        });
        this.setState({
            selectedProducts: selectedProducts,
            errorNothingSelected: false
        });
    };

    handleDecrementProduct = (productId) => {
        let selectedProducts = this.state.selectedProducts;
        selectedProducts = selectedProducts.map((product) => {
            if(product.id === productId && product.amount > 0) {
                product.amount -= 1;
            }
            return product;
        });
        this.setState({
            selectedProducts: selectedProducts
        });
    };

    handleSave = () => {
        let totalAmount = this.state.selectedProducts.reduce((current, product) => {
            return current + product.amount*(product.price*(product.tax+1));
        }, 0);
        if(totalAmount > 0) {
            this.props.saveStep1(this.state.selectedProducts);
        } else {
            this.setState({
                errorNothingSelected: true
            })
        }
    };

    render() {
        let totalAmount = this.state.selectedProducts.reduce((current, product) => {
            return Math.round(current + product.amount*(product.price*(product.tax+1)));
        }, 0);
        return (
            <div className="TicketingStep1">
                <HeadingWithLine lineColor="pink" textKey="ticketing.step1.title" biggerPaddingBottom/>
                <TicketingStepProgress activeStep={1}/>
                <div className="box">
                    <div className="line-left line-color-gray" />
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step1.box.title"/>
                    </h2>
                    <div className="text">
                        <IntlHtmlMessages id="ticketing.step1.box.text"/>
                    </div>
                    {this.state.errorNothingSelected ? (
                        <div className="errorNothingSelected">
                            <IntlHtmlMessages id="ticketing.step1.box.errorNothingSelected"/>
                        </div>
                    ) : null}
                    <div className="productsTable">
                        {this.state.selectedProducts.map((product, productIndex) => (
                            <div className="product" key={`p${productIndex}`}>
                                <div className={`col price-container${product.availability !== 'AVAILABLE' ? ' disabled' : ''}`}>
                                    {(product.price*(product.tax+1)).toFixed(0)} € {/*.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})}*/}
                                </div>
                                <div className={`col title-container${product.availability !== 'AVAILABLE' ? ' disabled' : ''}`}>
                                    <IntlMessages id={`products.${product.key}.title`}/>
                                </div>
                                <div className={`col selection-container${product.availability !== 'AVAILABLE' ? ' disabled' : (product.amount > 0 ? ' active' : '')}`}>
                                    {product.availability !== 'AVAILABLE' ? (
                                        <div className="not-available-overlay">
                                            {product.availability === 'SOLD_OUT' ? (
                                                this.props.settings.locale === 'de' ? (
                                                    <div className="image-container">
                                                        <img src={ImgSoldOutDesktopDe} alt="" className="img-desktop"/>
                                                        <img src={ImgSoldOutTabletDe} alt="" className="img-tablet"/>
                                                        <img src={ImgSoldOutMobileDe} alt="" className="img-mobile"/>
                                                    </div>
                                                ) : (
                                                    <div className="image-container">
                                                        <img src={ImgSoldOutDesktopEn} alt="" className="img-desktop"/>
                                                        <img src={ImgSoldOutTabletEn} alt="" className="img-tablet"/>
                                                        <img src={ImgSoldOutMobileEn} alt="" className="img-mobile"/>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    ) : null}
                                    <div className={`arrow-down${product.amount > 9 ? ' disabled' : ''}`} onClick={() => product.availability === 'AVAILABLE' ? this.handleDecrementProduct(product.id) : null}>
                                        {product.amount > 0 ? (
                                            <img src={ImgArrowDown} alt=""/>

                                        ) : (
                                            <img src={ImgArrowDownDisabled} alt=""/>
                                        )}
                                    </div>
                                    <div className="current-amount">
                                        {product.amount}
                                    </div>
                                    <div className={`arrow-up arrow-up-norm${product.amount < 1 ? ' disabled' : ''}`} onClick={() => product.availability === 'AVAILABLE' ? this.handleIncrementProduct(product.id) : null}>
                                        {product.amount < 10 ? (
                                            <img src={ImgArrowUp} alt=""/>
                                        ) : (
                                            <img src={ImgArrowUpDisabled} alt=""/>
                                        )}
                                    </div>
                                    <div className="arrow-up arrow-up-dis">
                                        <img src={ImgArrowUpDisabled} alt=""/>
                                    </div>
                                </div>
                                <div className={`product-price-container${product.availability !== 'AVAILABLE' ? ' disabled' : ''}`}>
                                    <div className="numbers-1">
                                        {(product.amount*Math.round(product.price*(product.tax + 1))).toLocaleString('de-De', {maximumFractionDigits: 0, minimumIntegerDigits:2})}
                                    </div>
                                    <div className="dot">,</div>
                                    <div className="numbers-2">
                                        {(product.amount*Math.round(product.price*(product.tax + 1))).toLocaleString('de-De', {maximumFractionDigits: 2, minimumFractionDigits:2}).split(',')[1]}
                                    </div>
                                    <div className="eur">€</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="products-total">
                        <div className="title">
                            <IntlHtmlMessages id="ticketing.step1.box.sum"/>
                        </div>
                        <div className="amount-container">
                            <div className={`amount${totalAmount > 0 ? ' active' : ''}`}>
                                <div className="numbers-1">
                                    {totalAmount.toLocaleString('de-De', {maximumFractionDigits: 0, minimumIntegerDigits:2})}
                                </div>
                                <div className="dot">,</div>
                                <div className="numbers-2">
                                    {totalAmount.toLocaleString('de-De', {maximumFractionDigits: 2, minimumFractionDigits:2}).split(',')[1]}
                                </div>
                                <div className="eur">€</div>
                            </div>
                            <div className="includingTax">
                                <IntlMessages id="ticketing.step1.box.includingTax"/>
                            </div>
                        </div>
                    </div>
                    <div className="ticketing-step1-submit">
                        <Button titleKey="ticketing.step1.box.submit" onClick={this.handleSave} disabled={true}/>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ticketing: state.ticketing,
    settings: state.settings
});

export default connect(mapStateToProps, {
    saveStep1
})(TicketingStep1);
