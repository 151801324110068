import React, {Fragment} from 'react';
import LoginMenu from "./components/LoginMenu";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import {withRouter} from "react-router";
import connect from "react-redux/src/connect/connect";
import {hideLoader, logout, showLoader} from "../../actions";
import {injectIntl} from "react-intl";
import LoginApi from "../../apis/Login";
import IntlMessages from "../../util/IntlMessages";
import moment from 'moment';
import Button from "../../components/elements/Button";
import closeImage from '../../assets/images/closeo_active.svg';
import closeImageHover from '../../assets/images/closeo.svg';

class LoginBuses extends  React.Component {

    state = {
        availableShuttleBuses: null,
        step: 'overview',
        optionsError: false
    };

    componentDidMount() {
        this.getAvailableShuttleBuses();
    }

    doLogout = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
        this.props.logout();
    };

    getAvailableShuttleBuses = () => {
        this.props.showLoader();
        LoginApi.getAvailableShuttleBuses(this.props.login.bearerToken)
            .then((response) => {
                this.props.hideLoader();
                if(response.data.success) {
                    this.setState({availableShuttleBuses: response.data.data});
                } else {
                    alert('An error occurred. Please try again.');
                }
            })
    };

    handleStartEdit = (orderItemCode) => {
        let selectedTicket = this.state.availableShuttleBuses.find((el) => el.code === orderItemCode);
        this.setState({
            step: 'options',
            selectedTicket: selectedTicket,
            selectedArrival: selectedTicket.shuttleBuses.selectedArrival,
            selectedReturn: selectedTicket.shuttleBuses.selectedReturn
        });
    };

    cancelOptions = () => {
        this.setState({
            step: 'overview',
            selectedTicket: null,
            selectedArrival: null,
            selectedReturn: null
        });
    };

    saveSelection = () => {
        this.props.showLoader();
        LoginApi.saveShuttleBuses(this.props.login.bearerToken, this.state.selectedTicket.code, this.state.selectedArrival ? this.state.selectedArrival.id : null, this.state.selectedReturn ? this.state.selectedReturn.id : null)
            .then((response) => {
                this.props.hideLoader();
                if(response.data.success) {
                    this.setState({
                        step: 'success',
                        selectedTicket: null,
                        selectedArrival: null,
                        selectedReturn: null
                    });
                    this.getAvailableShuttleBuses();
                } else {
                    this.setState({
                        optionsError: true
                    });
                }
            })
    };

    render() {
        let lastDay = null;
        return (
            <div className={`LoginBuses step-${this.state.step}`}>
                <LoginMenu active="buses"/>
                <div className="bus-content">
                    <div className="header">
                        <a href="javascript:" onClick={this.doLogout}>
                            <IntlHtmlMessages id="login.overview.logout"/>
                        </a>
                    </div>
                    <div className="box box-2">
                        {this.state.step === 'overview' ? (
                            <div className="overview">
                                <div className="header-container">
                                    <h2 className="title">
                                        <IntlHtmlMessages id="login.buses.overview.title"/>
                                    </h2>
                                    <div className="text">
                                        {this.state.availableShuttleBuses &&
                                        this.state.availableShuttleBuses.length > 0 ? (
                                            <Fragment>
                                                <p>
                                                    <IntlMessages id="login.buses.overview.text2.label"/>: <IntlMessages
                                                    id="login.buses.overview.text2.value"/>
                                                </p>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <p>
                                                    <IntlHtmlMessages id="login.buses.overview.noBuses.text"/>
                                                </p>
                                                <Button className="btn-noBuses" isLink to="/login/options"
                                                    titleKey="login.buses.overview.noBuses.buyNow"
                                                />
                                            </Fragment>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="tickets">
                                    {this.state.availableShuttleBuses ? (
                                        this.state.availableShuttleBuses.map((shuttleBus, shI) => (
                                            <div className="ticket" key={shI}>
                                                <div className="line-left line-color-gray"/>
                                                <div className="ticket-type">
                                                    <IntlMessages
                                                        id={`products.${shuttleBus.product}.titleWithTicket`}/><br/>
                                                    {shuttleBus.code}
                                                </div>
                                                <div className="ticket-status">
                                                    <IntlMessages
                                                        id="login.buses.overview.shuttleFrom"/> {shuttleBus.shuttleBuses.city}<br/>
                                                    {shuttleBus.shuttleBuses.selectedArrival && shuttleBus.shuttleBuses.selectedReturn ? (
                                                        <div>
                                                            <IntlMessages
                                                                id="login.buses.overview.arrivalAt"/>: {moment(shuttleBus.shuttleBuses.selectedArrival.startAt).format('HH:mm')}
                                                            <IntlMessages
                                                                id="login.buses.overview.returnAt"/>: {moment(shuttleBus.shuttleBuses.selectedReturn.startAt).format('HH:mm')}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <IntlMessages id="login.buses.overview.selectBuses"/>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="ticket-actions">
                                                    <Button
                                                        titleKey="login.buses.overview.edit"
                                                        onClick={() => this.handleStartEdit(shuttleBus.code)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : null}
                                </div>
                            </div>
                        ) : this.state.step === 'options' ? (
                            <div className="options">
                                <button className="closeButton" onClick={this.cancelOptions}>
                                    <img src={closeImage} alt=""/>
                                    <img className="loginOption-closeHover" src={closeImageHover} alt=""/>
                                </button>
                                {this.state.optionsError ? (
                                    <div className="error-container">
                                        <IntlHtmlMessages id="login.buses.options.error"/>
                                    </div>
                                ) : null}
                                <div className="header-container arrival">
                                    <h2 className="title">
                                        <IntlHtmlMessages id="login.buses.options.arrival"/>
                                    </h2>
                                    <div className="buses">
                                        {this.state.selectedTicket ? this.state.selectedTicket.shuttleBuses.arrivals.map((arrival, aI) => {
                                            let day = moment(arrival.startAt).format('dddd');
                                            let showDay = day !== lastDay;
                                            lastDay = day;
                                            return (
                                                <div className={`bus${showDay ? ' margin-top' : ''}${arrival.freeSlots < 1 ? ' disabled' : ''}`} key={aI}>
                                                    <div className="bus-day">
                                                        {showDay ? day : null}
                                                    </div>
                                                    <div className="bus-time">
                                                        <input type="checkbox" className="input-radio" id={`bus-arrival-${arrival.id}`} disabled={arrival.freeSlots < 1} checked={this.state.selectedArrival && this.state.selectedArrival.id === arrival.id} onClick={() => this.setState({selectedArrival: arrival})}/>
                                                        <label htmlFor={`bus-arrival-${arrival.id}`}>
                                                            {moment(arrival.startAt).format('HH:mm')}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                                <div className="return">
                                    <h2 className="title">
                                        <IntlHtmlMessages id="login.buses.options.return"/>
                                    </h2>
                                    <div className="buses">
                                        {this.state.selectedTicket ? this.state.selectedTicket.shuttleBuses.returns.map((returnB, rI) => {
                                            let day = moment(returnB.startAt).format('dddd');
                                            let showDay = day !== lastDay;
                                            lastDay = day;
                                            return (
                                                <div className={`bus${showDay ? ' margin-top' : ''}${returnB.freeSlots < 1 ? ' disabled' : ''}`} key={rI}>
                                                    <div className="line-left line-color-gray"/>
                                                    <div className="bus-day">
                                                        {showDay ? day : null}
                                                    </div>
                                                    <div className="bus-time">
                                                        <input type="checkbox" className="input-radio" id={`bus-arrival-${returnB.id}`} disabled={returnB.freeSlots < 1}  checked={this.state.selectedReturn && this.state.selectedReturn.id === returnB.id} onClick={() => this.setState({selectedReturn: returnB})}/>
                                                        <label htmlFor={`bus-arrival-${returnB.id}`}>
                                                            {moment(returnB.startAt).format('HH:mm')}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                                <div className="login-buses-options-submit">
                                    <Button
                                        titleKey="login.buses.options.submit"
                                        onClick={this.saveSelection}
                                    />
                                </div>
                            </div>
                        ) : this.state.step === 'success' ? (
                            <div className="success">
                                <div className="header-container">
                                    <h2 className="title">
                                        <IntlHtmlMessages id="login.buses.success.title"/>
                                    </h2>
                                    <div className="text">
                                        <p>
                                            <IntlMessages id="login.buses.success.text"/>
                                        </p>
                                        <Button
                                            titleKey="login.buses.success.back"
                                            onClick={() => this.setState({step: 'overview'})}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    login: state.login
});

export default connect(mapStateToProps, {
    logout, showLoader, hideLoader
})(injectIntl(withRouter(LoginBuses)));
