import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import Ticketing from './Ticketing';
import Loader from './Loader';
import Settings from './Settings';
import Login from "./Login";
import Supporter from "./Supporter";


const reducers = combineReducers({
    routing: routerReducer,
    ticketing: Ticketing,
    loader: Loader,
    settings: Settings,
    login: Login,
    supporter: Supporter
});

export default reducers;
