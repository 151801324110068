import React from "react";
import MicRightImage from "../../assets/images/right/right-mic.jpg";
import RightImage from "../../components/elements/RightImage";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import PodcastLink from "../../components/elements/PodcastLink";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import TextBlockWithLine from "../../components/elements/content/TextBlockWithLine";

class NoEntropie2024 extends React.Component {
  render() {
    return (
      <div className="PageDefault">
        <RightImage source={MicRightImage}/>
        <div className="content-container">
          <div className="content">
            <Logo/>
            <SocialMenu/>
            <Footer/>
            <Menu/>
            <PodcastLink/>
          </div>
        </div>
        <div className="left-text-column PageDefault-inner NoEntropie2024">
          <HeadingWithLine lineColor="pink" textKey="noEntropie2024.title"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.1"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.2"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.3"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.4"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.5"/>
          <TextBlockWithLine lineColor="pink" textKey="noEntropie2024.text.6"/>
        </div>
      </div>
    )
  }
}

export default NoEntropie2024;