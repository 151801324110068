import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga'
import etWebApp from './reducers/index';
import persistState from 'redux-localstorage';
import {clearGlobalState, setUuid} from './actions';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
    applyMiddleware(sagaMiddleware),
    persistState(null, {key: 'entropiefestival2022'})
);

const store = createStore(etWebApp, enhancer);
sagaMiddleware.run(rootSaga);
export {store}


function handleStoreChange() {
    let currentStore = store.getState();
    if(!currentStore.settings.uuid) {
        store.dispatch(setUuid());
    }
    if(((new Date()).getTime() - currentStore.settings.lastActionAt) > (1000*60*60*12)) {
        console.log('clear state');
        store.dispatch(clearGlobalState());
    }

}

const unsubscribe = store.subscribe(handleStoreChange);
//unsubscribe();
