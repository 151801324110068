import React from 'react';
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

class Button extends React.Component {
    render() {
        return (
            !!this.props.isRealLink ? (
                <a href={this.props.to} className={`Button${this.props.className ? ` ${this.props.className}` : ''}`} target={this.props.target ? this.props.target : '_self'}>
                    {this.props.children ? (
                        this.props.children
                    ) : <IntlMessages id={this.props.titleKey}/>}

                </a>
            ) : (
                !!this.props.isLink ? (
                    <Link to={this.props.to} className={`Button${this.props.className ? ` ${this.props.className}` : ''}`}>
                        <IntlMessages id={this.props.titleKey}/>
                    </Link>
                ) : (
                    <button className={`Button${this.props.className ? ` ${this.props.className}` : ''}`} onClick={this.props.onClick} disabled={this.props.disabled}>
                        {this.props.children ? (
                            this.props.children
                        ) : <IntlMessages id={this.props.titleKey}/>}
                    </button>
                )
            )

        )
    }
}

export default Button;