import {
    GLOBAL_CLEAR_STATE,
    TICKETING_BACK_TO_STEP_1, TICKETING_BACK_TO_STEP_2, TICKETING_CLEAR_STATE,
    TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST,
    TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS, TICKETING_PAYPAL_ERROR, TICKETING_PAYPAL_SUCCESS,
    TICKETING_SAVE_STEP_1, TICKETING_SAVE_STEP_2, TICKETING_SAVE_STEP_3
} from "../config/actions";

const INIT_STATE = {
    loader: false,
    step: 1,
    availableProducts: null,
    selectedProducts: null,
    order: null,
    orderSummary: null,
    isActive: false
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST: {
            return {
                ...state,
                loader: true,
                isActive: true
            }
        }
        case TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                availableProducts: action.payload,
                selectedProducts: null
            }
        }
        case TICKETING_SAVE_STEP_1: {
            let selectedProducts = action.payload;
            let products = [];
            let selectedProduct;
            let index = 0;
            for(let i = 0; i < selectedProducts.length; i++) {
                selectedProduct = selectedProducts[i];
                for(let j = 0; j < selectedProduct.amount; j++) {
                    products.push({id: index, productId: selectedProduct.id, product: {
                            ...selectedProduct,
                            productOptions: selectedProduct.productOptions.map((po) => {
                                return {...po}
                            })
                        }});
                    index++;
                }
            }
            return {
                ...state,
                loader: false,
                selectedProducts: selectedProducts,
                step: 2,
                order: {
                    products: products,
                    hardtickets: false
                }
            }
        }
        case TICKETING_SAVE_STEP_2: {
            return {
                ...state,
                step: 3,
                order: action.payload
            }
        }
        case TICKETING_SAVE_STEP_3: {
            return {
                ...state,
                step: action.payload.orderSummary.paymentType === 'PREPAYMENT' ? 7 : 4,
                orderSummary: action.payload.orderSummary
            }
        }
        case TICKETING_BACK_TO_STEP_1: {
            return {
                ...state,
                step: 1
            }
        }
        case TICKETING_BACK_TO_STEP_2: {
            return {
                ...state,
                step: 2
            }
        }
        case GLOBAL_CLEAR_STATE:
        case TICKETING_CLEAR_STATE: {
            return {
                ...INIT_STATE,
                availableProducts: state.availableProducts,
                isActive: false
            };
        }
        case TICKETING_PAYPAL_SUCCESS: {
            return {
                ...state,
                step: 5,
                orderSummary: action.payload
            }
        }
        case TICKETING_PAYPAL_ERROR: {
            return {
                ...state,
                step: 6
            }
        }
        default:
            return state;
    }
}