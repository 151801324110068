import React from 'react';
import MenuItem from "./MenuItem";
import SocialMenu from "../SocialMenu";
import IntlHtmlMessages from "../../../util/IntlHtmlMessages";
import Footer from "../Footer/Footer";
import {Link} from "react-router-dom";
import connect from "react-redux/src/connect/connect";
import {clearState} from "../../../actions";
import closeImage from '../../../assets/images/close.svg';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6';
import PodcastLink from "../PodcastLink";

class Menu extends React.Component {
    state = { showMenu: false };

    targetElement = null;

    toggleMenu = () => {
        if(this.state.showMenu) {
            enableBodyScroll(this.targetElement);
        } else {
            disableBodyScroll(this.targetElement);
        }
        this.setState({
            showMenu: !this.state.showMenu
        })
    };

    closeMenu = () => {
        this.setState({
            showMenu: false
        })
    };

    landscapeMenu = () => {
        let windowHeight = window.innerHeight;
        let newLayout = '';
        if (windowHeight < 480) {
            newLayout = 'landscape-menu';
        }

        this.setState({
            layout: newLayout
        })
    };
    componentWillMount() {
        this.landscapeMenu();
        window.addEventListener("resize", this.landscapeMenu);
    }
    componentDidMount() {
        this.targetElement = document.getElementById('mainmenu');
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.landscapeMenu);
        clearAllBodyScrollLocks();
    }

    handleResetTicket = () => {
        if(this.props.ticketing.step === 5 || this.props.ticketing.step === 7) {
            this.props.clearState();
        }
    };

    render() {
        const menuVis = this.state.showMenu ? 'show' : 'hide';
        return (
            <div className="Menu" id="mainmenu">
                <div className={`${this.state.layout}`}>
                    <ul id="menu" className={`menu ${menuVis}`}>
                        <div className="mobile-menu-title">
                            <IntlHtmlMessages id="home.title" />
                        </div>
                        <div className="menuItem-container">
                            <div className="mobile-menu-logo" onClick={this.closeMenu}>
                                <Link to="/">
                                </Link>
                            </div>
                            <MenuItem to="/lineup" titleKey="menu.lineup" onClick={this.closeMenu}/>
                            <MenuItem to="/tickets" onClick={() => {this.handleResetTicket();this.closeMenu();}} titleKey="menu.tickets"/>
                            <MenuItem to="/info" titleKey="menu.info" onClick={this.closeMenu}/>
                            <MenuItem to="/supporter" titleKey="menu.supporter" onClick={this.closeMenu}/>
                            {/*<MenuItem to="/login" titleKey="menu.login" onClick={this.closeMenu}/>*/}
                            <SocialMenu/>
                            <Footer/>
                            <PodcastLink onClick={this.closeMenu}/>
                        </div>
                    </ul>
                    <div className="hamburger-container" onClick={this.toggleMenu}>
                        <div className="hamburger">
                            <div className="line-1"/><div className="line-2"/><div className="line-3"/>
                            <img src={closeImage} alt="" className="close"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    clearState
})(Menu);