/* eslint-disable jsx-a11y/href-no-hash */
class Config {
    static api() {
        // return 'https://office.entropiefestival.de';
         return 'https://api.entropiefestival.de/';
    }

    static paypal() {
        return {
            env: 'production'
        }
    }
}

export default Config;
