import React from 'react';
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import $ from 'jquery';

class TicketingStepPretix extends React.Component {

    componentDidMount() {


        const script = document.createElement("script");

        script.src = "https://tickets.entropiefestival.de/widget/v1.de-informal.js";
        script.async = true;

        document.body.appendChild(script);

        /*$('.TicketingStepPretix').on('click', '.pretix-widget-availability-available', (t) => {
            t.stopPropagation();
            let input = $(t.target).find('input');
            if (input.val() && parseInt(input.val()) > 0) {
                input.val(parseInt(input.val()) - 1).change().click();
            }
        })
        $('.TicketingStepPretix').on('click', '.pretix-widget-availability-box', (t) => {
            let input = $(t.target).find('input');
            if (!input.val()) input.val(0);
            input.val(parseInt(input.val()) + 1).change().keydown().keyup();
        })*/
    }

    render() {
        return (
            <div className="TicketingStepPretix">
                <HeadingWithLine lineColor="pink" textKey="ticketing.step1.title" biggerPaddingBottom/>
                <div className="box">
                    <div className="line-left line-color-gray" />
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step1.box.title"/>
                    </h2>
                    {/*<div className="text">
                        <IntlHtmlMessages id="ticketing.step1.box.text"/>
                    </div>*/}

                    <pretix-widget event="https://tickets.entropiefestival.de/KeV/entropie2023/" skip-ssl-check></pretix-widget>
                    <noscript>
                        <div className="pretix-widget">
                            <div className="pretix-widget-info-message">
                                JavaScript ist in Ihrem Browser deaktiviert. Um unseren Ticket-Shop ohne JavaScript
                                aufzurufen, klicken Sie bitte <a target="_blank" rel="noopener"
                                                                 href="https://tickets.entropiefestival.de/KeV/entropie2023/">hier</a>.
                            </div>
                        </div>
                    </noscript>
                </div>
            </div>
        )
    }
}

export default TicketingStepPretix;
